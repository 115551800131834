body {
    margin: 0;
    font-family: Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.c_bundle_item_name,
.noclick,
.pbifr_border,
.x_signup_shade {
    pointer-events: none !important;
}


/* input[type="text"]:disabled {
background: #dddddd;
} */

select.cart_select:disabled {
    background: white;
    border: none;
    /* cursor: not-allowed; */
    cursor: text;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
}

select.cart_select {
    background-color: rgba(206, 140, 255, 0.27);
    border: 1px solid rgba(176, 122, 216, 0.4);
    /* cursor: not-allowed; */
    cursor: pointer;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    height: 1.8em;
    padding: 0.1em 0.6em;
}

.f_radio,
.k_for,
.k_price,
.button_cart {
    white-space: nowrap;
}

.k_li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.kb00 {
    scroll-behavior: smooth;
}

.k00 {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.grid-item:hover .item_hover {
    opacity: 1;
}

.grid-item--lg:nth-child(odd) .item_inner--lg {
    flex-direction: row;
}

.grid-item--lg:nth-child(even) .item_inner--lg {
    flex-direction: row-reverse;
}

@media (max-width: 991px) {
    .grid-item--lg:nth-child(odd) .item_inner--lg,
    .grid-item--lg:nth-child(even) .item_inner.item_inner--lg {
        flex-direction: row;
    }
}

@media (max-width: 767px) {}

@media (max-width: 479px) {}

.grid-item--lg {
    flex-direction: column;
}

.but--k--active .but_cta {
    right: auto;
    left: 4px;
}

.c_txt1_desc,
.c_txt1_desc.c_txt1_desc--lg,
.c_txt1_desc.c_txt1_desc--small,
.c_txt1_desc.c_txt1_desc--slider2 {
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.c_txt1_desc.c_txt1_desc--small {
    -webkit-line-clamp: 3;
}

.f_filter-input {
    -webkit-appearance: none;
}

.pb_bundle_1 {
    cursor: default;
}

.c_pic {
    overflow: hidden;
}

.c_pic:hover .c_bundle_item_name {
    opacity: 1;
    transform: translateY(0);
}

.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--2:nth-child(even) {
    transform: translateY(36px)
}

.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--2:nth-child(odd) {
    transform: translateY(-36px)
}

.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--5:nth-child(1),
.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--5:nth-child(3),
.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--5:nth-child(4),
.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--5:nth-child(6),
.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--5:nth-child(7),
.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--5:nth-child(9) {
    transform: translateY(30px)
}

.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--3:nth-child(1) {
    transform: translateY(30px)
}

.c_pic_bundle0:not(.c_pic_bundle0--k) .c_pic_bundle--3:nth-child(3) {
    transform: translateX(20px)
}

.k_coupon0 {
    background-color: #f9f0ff;
    justify-content: space-between;
    padding: 1em 32px;
}

.k_cart_error {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 1em 36px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #222;
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 700;
    background-color: rgba(244, 67, 54, 0.25);
}

.k_cart_error-icon {
    display: inline-block;
    width: 16px;
    margin-right: 6px;
}

.k_bot {
    padding-top: 0;
}

.k_item.inactive {
    opacity: 0.3;
}

.c_pic_actions0 {
    background-color: transparent;
    opacity: 1;
    -webkit-transition: opacity 200ms, background-color 200ms ease;
    transition: opacity 200ms, background-color 200ms ease;
}

.c_pic_actions0:hover {
    background-color: rgba(132, 0, 197, 0.1);
}

.c_pic_actions0:hover .c_pic_action {
    opacity: 1;
}

.c_pic_action {
    opacity: 0;
}

.c_game_delete {
    padding-bottom: 0;
}

.f_filter-input:hover + .f_filter_search_icon {
    color: #fff;
}

.f_filter-input:focus + .f_filter_search_icon {
    color: #400576;
}

.f_filter-input.f_filter-input--active + .f_filter_search_icon,
.f_filter-input.f_filter-input--active:hover + .f_filter_search_icon {
    color: #400576;
}

.f_filter-block {
    width: 100%;
}

@media screen and (max-width: 991px) {
    @supports (-webkit-touch-callout: none) {
        .pbifr0 {
            min-height: calc(var(--vh, 1vh) * 100) !important
        }
    }

    .f_radio {
        padding-left: 1em;
        padding-right: 1em;
    }
}

.but.but--2.but--2--small {
    margin-right: 0;
}

.but.but--k.but--k--small {
    padding-left: 1em;
}

.but--k--slider2 {
    padding-right: 4em;
}

.but, .k_price {
    font-family: Codecpro, sans-serif, 'Helvetica Neue';
}

.k0.shown {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.button_cart.button_cart--apply_code {
    padding-right: 2em;
    padding-left: 2em;
    border-radius: 100px;
    background-color: #58c15b;
    box-shadow: none;
    -webkit-transition-duration: 200ms, 200ms, 200ms, 500ms;
    transition-duration: 200ms, 200ms, 200ms, 500ms;
    color: #fff;
}

.button_cart.button_cart--apply_code:hover {
    background-color: #8400c5;
}

.f_filter-input0:hover .input-icon:not(.f_filter_search_icon) {
    color: #fff;
}

select:focus ~ .input-icon:not(.f_filter_search_icon)  {
    color: #400576 !important;
}

.input-icon {
    pointer-events: none;
}

.button_cart.button_cart--apply_code.button_cart--disabled {
    background-color: #d6dfd6;
    cursor: not-allowed;
}

.button_cart.button_cart--apply_code.button_cart--disabled:hover {
    background-color: #a2a7be;
}

.k_coupon.w-embed {
    width: 100%;
    padding-right: 16px;
}

@media screen and (max-width: 767px) {
    .k_coupon.w-embed {
        padding-right: 0px;
    }

    .button_cart.button_cart--apply_code {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .k_coupon0 {
        padding: 1em 16px;
    }

    .button_cart.button_cart--apply_code {
        margin-right: 0;
        margin-left: 0;
    }
}

.k_input--disabled {
    opacity: 0.5;
}

.k_li_total.hide_discount {
    display: none;
}

.txt_bonus {
    color: #367a38;
    font-weight: 700;
}

.preloader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5e7d83c69c1e1a4294cbee5c_preloader.gif);
    background-position: 50% 50%;
    background-size: 20px;
    background-repeat: no-repeat;
}

.preloader.hide {
    display: none;
}

.pbifr0 {
    min-height: 100% !important;
}

.pbifr0.w-iframe {
    background: transparent;
}

.k00_trigger {
    z-index: 999;
}

.c_pic_bundle.c_pic_bundle--k {
    border-radius: 4px;
}

.pz {
    display: flex;
    flex-direction: column-reverse;
}

@media screen and (max-width: 767px) {
    .pz.shown[data-a='target'] {
        z-index: 0;
    }
}
body {
    background: black;
    color: white;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

a {
    color: currentColor;
}

.item_container:hover .c_pic0,
.item_container:hover .s_pic_big {
    transform: scale(1.01)
}

.but {
    white-space: nowrap;
}

.item_loader {
    height: 12em;
    background-image: url(https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5e7d83c69c1e1a4294cbee5c_preloader.gif);
    background-position: 50% 50%;
    background-size: 20px;
    background-repeat: no-repeat;
}

.hide {
    display: none;
}

.k_li_price_old {
    margin-top: 4px;
    opacity: 0.38;
}
.k_price.k_li_price_old {
    margin-top: 0px;
    text-decoration: line-through;
}

.h_search_inp::placeholder {
    color: #fff;
    opacity: 0.5;
}

.h_search_inp:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
    opacity: 0.5;
}

.h_search_inp::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
    opacity: 0.5;
}
.k_login_email0 {
    font-size: 13px;
    font-weight: 700;
    color: #18611b;
}

.s_name_tag {
    height: 28px;
}

@media screen and (max-width: 767px) {
    .h_search0.active {
        min-width: 80px;
    }
}

@media screen and (min-width: 768px) {
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 80px;
    }

    .slider_bundles.swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
    }
}

@media screen and (min-width: 768px) {
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 80px;
    }

    .slider_bundles.swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
    }
}
