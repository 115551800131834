.game-banner {
  position: relative;
  width: 90%;
  max-width: 1140px;
  margin-bottom: 60px;
  padding: 20px;
  border-radius: 20px;
  background-position: 0% 90%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('https://cdn3.xsolla.com/files/uploaded/67256/4982000d1e7011dff0139f0a45df6b97.png');
}

.game-banner__image {
  width: 100%;
  background-size: cover;
}


@media (max-width: 991px) {
  .game-banner {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .game-banner {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
  }
}
