.cookie_banner {
    background-color: #400576;
    box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    width: 480px;
    height: 296px;
    padding: 40px;
}

.cookie_banner__chef {
    background-image: url(./Icons/Overcooked2Desktop.png);
    width: 182px;
    height: 189px;
    margin-top: -120px;
    background-size: 182px 189px;
}

.cookie_banner__close {
    position: absolute;
    right: 40px;
    cursor: pointer;
    opacity: 0.5;
}

.cookie_banner__header {
    text-transform: uppercase;
    font-family: 'Codecpro ultra', sans-serif;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;
}

.cookie_banner__text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    font-family: 'Eina01';
}

.cookie_banner__text a {
    font-weight: bold;
}

.cookie_banner__accept {
    text-transform: uppercase;
    line-height: 14px;
    color: #f77d00;
    font-family: 'Codecpro ultra', sans-serif;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
}

.cookie_banner__border {
    border: 2px solid #f77d00;
    width: 70px;
}

.background_cookie_banner {
    background-color: #000;
    opacity: 0.5;
    z-index: 1001;
    width: 100%;
    height: 100%;
    position: fixed;
}

@media screen and (max-width: 767px) {
    .cookie_banner {
        width: 336px;
        height: 207px;
        padding: 20px;
    }
    .cookie_banner__chef {
        background-image: url(./Icons/Overcooked2mobile.png);
        width: 80px;
        height: 107px;
        margin-top: -60px;
        background-size: 80px 107px;
    }
    .cookie_banner__header {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 5px;
        margin-top: 8px;
    }
    .cookie_banner__text {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }
}